
import { defineComponent, reactive, ref, nextTick, provide } from 'vue';
import { QueryListType, SearchListType, checkSubmitStatus, EnumCheckStatus } from '@/views/VehicleLicense/types';
import LicenseSub from '@/views/VehicleLicense/VehicleLicenseSubmit/LicenseSubmit/index.vue';
import BatchUploadSubmit from '@/views/VehicleLicense/VehicleLicenseSubmit/LicenseSubmit/BatchUploadSubmit.vue';
import NoticeModal from '@/components/NoticeModal.vue';
import {
  cancelUpload,
  getfileSize,
  cancelUploadFileMulti,
  getBasicDetail,
  singleLicenseCallback,
  singleLicenseSumbit,
  batchVLSubmit,
  getOverviewNum,
  getSearchResult
} from '@/API/vl/vlOverview';
import downloadFile from '@/utils/rv/downloadFile';
import setColumnWidth from '@/utils/rv/setColumnWidth';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
import { message } from 'ant-design-vue';
import LicenseDetail from '@/views/VehicleLicense/LicenseDetail/index.vue';
import ModalWarning from '@/views/VehicleLicense/Modal/ModalWarning.vue';
import BatchSubmitModal from '@/views/VehicleLicense/Modal/BatchSubmitModal.vue';
import moment from 'moment';
import { useStore } from 'vuex';
import BrandSelector from '@/views/RetailValidation/component/BrandSelector.vue';
// 表格数据
const columns = reactive([
  { title: '车架号', dataIndex: 'vinNo', align: 'center', width: 168 },
  {
    title: '提交状态',
    dataIndex: 'submissionStatus',
    align: 'center',
    width: 100,
    slots: { customRender: 'submissionStatus' }
  },
  {
    title: '车牌号码',
    dataIndex: 'licenseNumber',
    align: 'center',
    width: 100
  },
  {
    title: '注册日期',
    dataIndex: 'registrationDate',
    align: 'center',
    width: 100
  },
  {
    title: '提交日期',
    dataIndex: 'submissionDate',
    align: 'center',
    width: 110
  },
  { title: '批发来源', dataIndex: 'entity', align: 'center', width: 90 },
  { title: '品牌/车型', dataIndex: 'brand', align: 'center', width: 90 },
  { title: '客户名称', dataIndex: 'customerName', align: 'center', width: 104 },
  { title: '零售类型', dataIndex: 'usage', align: 'center', width: 104 },
  { title: '零售日期', dataIndex: 'retailDate', align: 'center', width: 100 },
  { title: '发动机号', dataIndex: 'engineNo', align: 'center', width: 110 },
  { title: '车型', dataIndex: 'model', align: 'center', width: 100 },
  {
    title: '激励适用性',
    dataIndex: 'checkStatus',
    align: 'center',
    width: 100,
    slots: { customRender: 'checkStatus' }
  },
  {
    title: '操作',
    fixed: 'right',
    width: 100,
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]);
const brandArray = ['MB', 'AMG', 'DENZA', 'SMART', 'EQ', '全部'];
const saleArray = ['最终用户用车', '经销商用车', '全部'];

const tableWidth = window.innerWidth;
export default defineComponent({
  components: {
    LicenseSub,
    BatchUploadSubmit,
    NoticeModal,
    LicenseDetail,
    ModalWarning,
    BrandSelector,
    BatchSubmitModal
  },
  setup() {
    const dealerCode = useStore().state.user.organization.entityCode;
    const provideObj = reactive<any>({});
    provide('provideObj', provideObj);
    const detailData = reactive({
      retailInfoRespVO: {},
      tengShiRespVO: {}
    });
    const loginType = ref(true);
    const tableHeight = ref();
    const visible = ref<boolean>(false);
    const isBatchVisible = ref<boolean>(false);
    const subLicense = ref();
    const searchCon = ref();
    // 批量复制
    const textContentNo = ref<string>('');
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    const tableData = ref([]);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0
    });
    //筛选条件，各个信息框的内容
    const searchParams = reactive<QueryListType>({
      retailPeriod: [],
      brand: undefined,
      vinNo: '',
      vinNos: [],
      usage: '全部',
      submitStatus: '',
      registrationPeriod: [],
      submissionPeriod: []
    });
    // 设置搜索值
    const params = reactive<SearchListType>({
      retailStartDate: '',
      retailEndDate: '',
      brand: undefined,
      vinNo: '',
      vinNos: [],
      usage: '全部',
      submitStatus: '',
      registrationStartDate: '',
      registrationEndDate: '',
      submissionStartDate: '',
      submissionEndDate: '',
      current: 1,
      size: 20
    });

    // 批量查询
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });

    // 重置查询条件
    const resetEvent = () => {
      searchParams.retailPeriod = [];
      searchParams.brand = '全部';
      searchParams.vinNo = '';
      searchParams.vinNos = [];
      searchParams.usage = '全部';
      searchParams.registrationPeriod = [];
      searchParams.submissionPeriod = [];
    };
    // 设置步骤默认值
    const currentStep = ref<number>(0);
    searchParams.submitStatus = '0000';
    // 设置步骤数据
    const stepData = reactive([
      {
        id: 0,
        number: 1,
        title: '上传行驶证',
        status: '待上传',
        statusNumber: ''
      },
      {
        id: 1,
        number: 2,
        title: '确认信息并提交',
        status: '待提交',
        statusNumber: ''
      },
      {
        id: 2,
        number: 3,
        title: '提交成功',
        status: '提交成功',
        statusNumber: ''
      }
    ]);
    const clickCount = ref<number>(0);
    const getTableScroll = () => {
      nextTick(() => {
        //  默认底部分页50 + 边距10
        const extraHeight = 110;
        const tHeader = document.getElementsByClassName('table-box')[0];
        //表格内容距离顶部的距离
        let tHeaderBottom = 0;
        if (tHeader) {
          tHeaderBottom = tHeader.getBoundingClientRect().top;
        }
        //窗体高度-表格内容顶部的高度-表格内容底部的高度
        const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
        tableHeight.value = height;
        if (!(tableData.value && tableData.value.length > 0)) {
          (document.getElementsByClassName('ant-table-placeholder')[0] as any).style.height = `calc(100vh - ${
            tHeaderBottom + 60
          }px)`;
        }
      });
    };

    // 获取params
    const getParams = () => {
      params.retailStartDate = '';
      params.retailEndDate = '';
      params.brand = '';
      params.vinNo = '';
      params.vinNos = [];
      params.usage = '';
      params.submitStatus = '';
      params.registrationStartDate = '';
      params.registrationEndDate = '';
      params.submissionStartDate = '';
      params.submissionEndDate = '';
      params.current = pagination.currentPage;
      params.size = pagination.pageSize;

      if (searchParams.retailPeriod.length > 0) {
        params.retailStartDate = searchParams.retailPeriod[0];
        params.retailEndDate = searchParams.retailPeriod[1];
      }

      if (searchParams.brand !== null && searchParams.brand !== '') {
        params.brand = searchParams.brand;
      }

      if (searchParams.vinNo) {
        params.vinNo = searchParams.vinNo.trim();
      }

      if (searchParams.vinNos.length > 0) {
        params.vinNos = cleanEmptyEleArray(searchParams.vinNos);
      }

      if (searchParams.usage !== null && searchParams.usage !== '') {
        params.usage = searchParams.usage;
      }

      // //有改动
      // if (currentStep.value !== 2 && currentStep.value !== 1) {
      //   if (searchParams.usage == "经销商用车") {
      //     params.usage = "null";
      //   } else if (searchParams.usage == "全部") {
      //     params.usage = "最终用户用车";
      //   }
      // }
      // // 判断是都点击了数字 有改动
      // if (clickCount.value == 1) {
      //   if (currentStep.value == 2) {
      //     params.usage = "最终用户用车";
      //   } else {
      //     params.usage = searchParams.usage;
      //   }
      //   clickCount.value = 0;
      // }

      if (searchParams.submitStatus !== null && searchParams.submitStatus !== '') {
        params.submitStatus = searchParams.submitStatus;
      }

      if (searchParams.registrationPeriod.length > 0) {
        params.registrationStartDate = searchParams.registrationPeriod[0];
        params.registrationEndDate = searchParams.registrationPeriod[1];
      }

      if (searchParams.submissionPeriod.length > 0) {
        params.submissionStartDate = searchParams.submissionPeriod[0];
        params.submissionEndDate = searchParams.submissionPeriod[1];
      }
      const paramData = params;
      return paramData;
    };
    //根据筛选框信息，查询表格
    const getTableData = () => {
      getParams();
      getSearchResult(params).then((res: any): void => {
        tableData.value = res.content;
        pagination.total = res.totalElements;
        const customerColumn = ['model', 'customerName'];
        tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn));
        getTableScroll();
      });
    };

    // 获取状态数量
    const getOverview = () => {
      getParams();
      getOverviewNum(params).then((res: any): void => {
        stepData[0].statusNumber = res.unupload;
        stepData[1].statusNumber = res.unsubmit;
        stepData[2].statusNumber = res.submit;
      });
    };

    // 点击切换状态
    const toggleTab = (id: number) => {
      currentStep.value = id;
      if (id == 0) {
        searchParams.submitStatus = '0000'; // 未上传
      } else if (id == 1) {
        searchParams.submitStatus = '1111'; // 上传未提交
      } else {
        searchParams.submitStatus = '2222'; // 已提交
      }
      pagination.currentPage = 1;
      pagination.pageSize = 20;
      getTableData();
      getOverview();
    };
    // 点击数字
    const clickNum = () => {
      clickCount.value = 1;
    };
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };

    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      textNoArray.value =
        cleanEmptyEleArray(searchParams.vinNos)[0] === '' ? [] : cleanEmptyEleArray(searchParams.vinNos);
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = '车架号批量查询';
      batchQuery.batchQueryListTitle = '车架号列表';
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchParams.vinNo = searchParams.vinNos[0];
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ',')
          .split(',')
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      searchParams.vinNos = textNoArray.value;
      // searchParams.vinNos = [];
      // searchParams.vinNos = params.vinNos.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchParams.vinNos = [];
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    // 单张提交
    const isSubVisible = ref<boolean>(false);
    const btnCon = ref<string>('');
    const selectInfo = reactive([]);
    //弹窗显示
    const modalVisible = ref<boolean>(false);
    const duration = ref(0);
    const maskClosable = ref(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });
    //打开批量按钮弹窗
    const upload = () => {
      isBatchVisible.value = true;
    };
    const canEdit = ref(false);
    const canDelete = ref(false);
    //基本信息
    const editLicense = (record: any) => {
      canEdit.value = currentStep.value === 2;
      canDelete.value = true;
      provideObj.entity = record.entity;
      provideObj.brand = record.brand;
      provideObj.submissionStatus = record.submissionStatus;
      provideObj.usage = record.usage;
      loginType.value = false;
      sessionStorage.setItem('editVlLincese', record.vinNo);
      sessionStorage.setItem('editVlDealerCode', record.dealerCode);
      const params = { vinNo: record.vinNo, dealerCode: record.dealerCode };
      getBasicDetail({ params }).then((res: any) => {
        Object.assign(detailData, res);
      });
    };
    const detailLicense = (record: any) => {
      canEdit.value = currentStep.value === 2;
      canDelete.value = false;
      provideObj.entity = record.entity;
      provideObj.brand = record.brand;
      provideObj.submissionStatus = record.submissionStatus;
      loginType.value = false;
      sessionStorage.setItem('editVlLincese', record.vinNo);
      sessionStorage.setItem('editVlDealerCode', record.dealerCode);
      const params = { vinNo: record.vinNo, dealerCode: record.dealerCode };
      getBasicDetail({ params }).then((res: any) => {
        Object.assign(detailData, res);
      });
    };
    const handleShow = () => {
      loginType.value = true;
      getOverview();
      getTableData();
    };
    // 单张提交逻辑
    const uploadLicense = async (record: any) => {
      if (record.usage == '经销商用车') {
        duration.value = 0;
        maskClosable.value = false;
        modalVisible.value = true;
        modalParams.noticeType = 'warning';
        modalParams.content = '需至零售校验模块(RV)提交行驶证';
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        if (record.licensePath) {
          await getfileSize(record.vinNo, record.dealerCode).then((res: any) => {
            sessionStorage.setItem('vlLicenseFileSize', res);
          });
        }
        isSubVisible.value = true;
        Object.assign(selectInfo, record);
      }
    };
    // 关闭发票提交和行驶证提交弹窗
    const closeSub = (param: { isSubVisible: boolean; isSuccess: boolean }) => {
      if (param.isSuccess) {
        isSubVisible.value = param.isSubVisible;
      }
      // 提交成功刷新数据
      getOverview();
      getTableData();
    };
    //关闭批量上传弹窗
    const closeBatch = (param: { visible: boolean }) => {
      isBatchVisible.value = param.visible;
      getOverview();
      getTableData();
    };
    const getModalInfo = (
      param: {
        modalVisible: boolean;
        modalData: object;
      },
      maskClosableOption = false,
      durationSeconds = 0
    ) => {
      maskClosable.value = maskClosableOption;
      duration.value = durationSeconds;
      modalVisible.value = param.modalVisible;
      Object.assign(modalData, param.modalData);
    };
    const cancelLicenseUpload = (vinNo: any) => {
      cancelUpload(vinNo, dealerCode);
      getOverview();
      getTableData();
    };
    // 关闭信息弹窗
    const closeModal = (param: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = param.isVisible;
      // 若continue为true 则调用取消上传方法
      if (param.continue) {
        if (isBatchVisible.value) {
          isBatchVisible.value = false;
          const batchNo: any = sessionStorage.getItem('batchNo');
          cancelUploadFileMulti(batchNo);
          sessionStorage.setItem('batchNo', '');
        } else if (isSubVisible.value) {
          isSubVisible.value = false;
          subLicense.value.closeSub();
          const vinNo = sessionStorage.getItem('vlLicenseVINNo');
          cancelLicenseUpload(vinNo);
          sessionStorage.setItem('vlLicenseVINNo', '');
        } else {
          const vinNo: any = sessionStorage.getItem('recallLicenseVinNO');
          const dealerCode: any = sessionStorage.getItem('recallLicenseDealerCode');
          singleLicenseCallback(vinNo, dealerCode);
          sessionStorage.setItem('recallLicenseVinNO', '');
          duration.value = 0;
          maskClosable.value = false;
          modalVisible.value = true;
          modalParams.noticeType = 'success';
          modalParams.content = '撤回成功';
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          setTimeout(() => {
            getOverview();
            getTableData();
          }, 1000);
        }
      }
    };
    const resultExport = () => {
      const downparams = {
        url: './rvapi/vl/dealer/resultExport',
        method: 'post',
        params: params,
        fileName: `${moment(new Date()).format('YYYYMMDDHHmmss')}.xlsx`
      };
      downloadFile(downparams);
    };
    const uploadMuitiBtn = ref(false);
    //单张提交
    const modalWarning = ref(false);
    const submitLicense = (record: any) => {
      sessionStorage.setItem('submitLicenseVinNo', record.vinNo);
      sessionStorage.setItem('submitLicenseDealerCode', record.dealerCode);
      modalWarning.value = true;
      uploadMuitiBtn.value = false;
    };
    //批量提交
    const uploadMuiti = () => {
      modalWarning.value = true;
      uploadMuitiBtn.value = true;
    };
    const batchSubmitVisible = ref(false);
    const batchSubmitTableData = ref<any>([]);
    const closewarning = (params: { modalWarning: boolean; submitContinue: boolean }) => {
      modalWarning.value = params.modalWarning;
      if (params.submitContinue) {
        if (uploadMuitiBtn.value) {
          const getVlParam = getParams();
          batchVLSubmit(getVlParam).then((res): any => {
            batchSubmitVisible.value = true;
            batchSubmitTableData.value = res.data;
            // if (res.code !== "0") {
            //   const errorString = "";
            //   sessionStorage.setItem("muchContent", JSON.stringify(res.data));
            //   duration.value = 0;
            //   maskClosable.value = false;
            //   modalVisible.value = true;
            //   modalParams.noticeType = "warning";
            //   modalParams.content = errorString;
            //   modalParams.btnVisible = false;
            //   Object.assign(modalData, modalParams);
            // } else {
            //   duration.value = 0;
            //   maskClosable.value = false;
            //   modalVisible.value = true;
            //   modalParams.noticeType = "success";
            //   modalParams.content = `${pagination.total}` + "条记录提交成功";
            //   modalParams.btnVisible = false;
            //   Object.assign(modalData, modalParams);
            //   getOverview();
            //   getTableData();
            // }
          });
        } else {
          const vinNo: any = sessionStorage.getItem('submitLicenseVinNo') || '';
          const dealerCode = sessionStorage.getItem('submitLicenseDealerCode') || '';
          singleLicenseSumbit(vinNo, dealerCode).then((res): any => {
            if (res.code === '0') {
              duration.value = 0;
              maskClosable.value = false;
              modalVisible.value = true;
              modalParams.noticeType = 'success';
              modalParams.content = '提交成功';
              Object.assign(modalData, modalParams);
              getOverview();
              getTableData();
            } else {
              duration.value = 0;
              maskClosable.value = false;
              modalVisible.value = true;
              modalParams.noticeType = 'warning';
              modalParams.content = 'VIN:' + vinNo + ',' + res.message;
              Object.assign(modalData, modalParams);
            }
          });
          sessionStorage.setItem('submitLicenseVinNo', '');
        }
      }
    };
    const closeBatchSubmit = () => {
      batchSubmitVisible.value = false;
      getOverview();
      getTableData();
    };
    //单车撤回
    const recallLicense = (record: any) => {
      if (record.usage === '经销商用车' || !record.deadlineFlag) {
        duration.value = 0;
        maskClosable.value = false;
        modalVisible.value = true;
        modalParams.noticeType = 'warning';
        modalParams.content = '需至零售校验模块(RV)撤回行驶证';
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        sessionStorage.setItem('recallLicenseVinNO', record.vinNo);
        sessionStorage.setItem('recallLicenseDealerCode', record.dealerCode);
        duration.value = 0;
        maskClosable.value = false;
        modalVisible.value = true;
        modalParams.noticeType = 'warning';
        modalParams.content = '是否要撤回？';
        modalParams.btnVisible = true;
        Object.assign(modalData, modalParams);
      }
    };
    getOverview();
    getTableData();

    return {
      provideObj,
      uploadMuiti,
      closewarning,
      uploadMuitiBtn,
      submitLicense,
      modalWarning,
      loginType,
      handleShow,
      editLicense,
      detailLicense,
      columns,
      tableData,
      searchParams,
      stepData,
      currentStep,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      pagination,
      toggleTab,
      resetEvent,
      setBatchQueryTextNoArray,
      setBatchPlaceholder,
      batchQueryEvent,
      closeBatchQueryModal,
      textChangEvent,
      clearBatchQuery,
      getOverview,
      getTableData,
      pageChange,
      sizeChange,
      checkSubmitStatus,
      EnumCheckStatus,
      params,
      resultExport,
      clickNum,
      canEdit,
      canDelete,
      isBatchVisible,
      modalVisible,
      duration,
      maskClosable,
      visible,
      brandArray,
      saleArray,
      open,
      close,
      upload,
      closeBatch,
      tableHeight,
      tableWidth,
      isSubVisible,
      btnCon,
      selectInfo,
      uploadLicense,
      modalData,
      modalParams,
      closeSub,
      getModalInfo,
      closeModal,
      cancelLicenseUpload,
      subLicense,
      detailData,
      searchCon,
      recallLicense,
      getTableScroll,
      batchSubmitTableData,
      batchSubmitVisible,
      closeBatchSubmit
    };
  }
});
