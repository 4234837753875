
import { defineComponent, ref } from 'vue'
import { getOperRecordDetail } from '@/API/vl/vlOverview'
const tableHeight = window.innerHeight - 300
export default defineComponent({
  setup() {
    const columns = [
      { title: '操作角色', width: '15%', dataIndex: 'opRole', key: 'opRole' },
      {
        title: '操作动作',
        width: '15%',
        dataIndex: 'opAction',
        key: 'opAction',
      },
      { title: '操作时间', dataIndex: 'opTime', key: 'opTime' },
    ]
    const data = ref([])
    const current = ref(1)
    const size = ref(20)
    const total = ref(0)
    const initData = () => {
      const params = {
        vinNo: sessionStorage.getItem('editVlLincese'),
        dealerCode: sessionStorage.getItem('editVlDealerCode'),
        current: current.value,
        size: size.value,
      }
      getOperRecordDetail({ params }).then((res): any => {
        data.value = res.content
        total.value = res.totalElements
      })
    }
    initData()
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1
      size.value = pageSize
      initData()
    }
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1
      size.value = pageSize
      initData()
    }
    return {
      columns,
      data,
      total,
      size,
      pageChange,
      sizeChange,
      tableHeight,
    }
  },
})
