
import { defineComponent, ref, reactive } from "vue";
import { FileItem } from "@/API/types";
import {
  licenseUpload,
  licenseResult,
  cancelSingleUpload
} from "@/API/vl/vlOverview";
import { useStore } from "vuex"
const columns = [
  {
    title: "车架号",
    dataIndex: "vinNo",
    key: "vinNo",
    width: 100
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    key: "fileName",
    width: 150
  },
  {
    title: "识别结果",
    dataIndex: "rtnMessage",
    key: "rtnMessage",
    width: 150,
    slots: { customRender: "rtnMessage"}
  },
];
export default defineComponent({
  components: {},
  emits: ["close-sub-visible", "get-modal-info"],
  props: {
    isSubVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectInfo: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  setup(props, context) {
    // 获取相关数据
    const modal = (props as any).isSubVisible;
    const data = (props as any).selectInfo;
    const licensePath = data.licensePath;
    const dealerCode = useStore().state.user.organization.entityCode
    // 文件上传
    const licenseFormData = new FormData();
    const licenseFileName = ref<string>("");
    const licenseFileSize = ref<string>("");
    const licenseUploadComplete = ref<boolean>(true);
    // 上传进度条
    const licensePercent = ref<number>(0);
    const licenseContent = ref<string>("0%完成");
    const licenseProStatus = ref<string>("normal");
    const isLicenseSuccess = ref<boolean>(false);
    const isSuccess = ref<boolean>(false);
    const licenseConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          licensePercent.value = complete;
          licenseContent.value = complete + "%完成";
        }
      },
    };
    // OCR进度条
    const licensePercentOCR = ref<number>(0);
    const licenseContentOCR = ref<string>("0%完成");
    const licenseProStatusOCR = ref<string>("normal");
    const licenseSubmitSuccess = ref<boolean>(false);
    const isContinue = ref<boolean>(false);
    const tableData = ref([]);
    const licenseFileData = reactive([]);
    const timer = ref<number>(0);
    const isInit = ref<boolean>(true);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    const closNum = ref<number>(0);

    const close = () => {
      let isUpdate = true;
      if ((isLicenseSuccess.value && tableData.value[0]) || isInit.value) {
        isSuccess.value = true;
        if (isInit.value) {
          isUpdate = false;
        }
        context.emit("close-sub-visible", { isSubVisible: false, isSuccess: isSuccess });
      } else {
        if (isLicenseSuccess.value) {
          modalParams.noticeType = 'warning';
          modalParams.content = 'OCR识别中，关闭窗口后可在任务中心关注后续进程及结果？';
          modalParams.btnVisible = false;
          isSuccess.value = false;
          Object.assign(modalData, modalParams);
          context.emit("close-sub-visible", { isSubVisible: false, isSuccess: isSuccess });
        } else {
          modalParams.noticeType = 'warning';
          modalParams.content = '正在上传行驶证，此操作会取消此次上传，确定要关闭窗口？';
          modalParams.btnVisible = true;
          isSuccess.value = false;
          sessionStorage.setItem("vlLicenseVINNo", data.vinNo);
          Object.assign(modalData, modalParams);
        }
        context.emit("get-modal-info", { modalVisible: true, modalData: modalData });
      }
    };

    const closeSub = () => {
      closNum.value = 1;
      clearInterval(timer.value);
      timer.value = 0;
      context.emit("close-sub-visible", { isSubVisible: false, isSuccess: isSuccess });
    }

    // 获取结果
    const getLicenseResult = async () => {
      if (closNum.value == 1) {
        closNum.value = 0;
      } else {
        try {
          const res = await licenseResult(data.vinNo, dealerCode);
          if (res.code !== '0') {
            licenseSubmitSuccess.value = false;
            licenseProStatusOCR.value = 'exception';
            licenseContentOCR.value = '识别失败';
            clearInterval(timer.value);
            timer.value = 0;
          } else {
            if (res.data[0]) {
              licenseSubmitSuccess.value = true;
              isContinue.value = true;
              licenseProStatusOCR.value = 'normal';
              licensePercentOCR.value = 100;
              licenseContentOCR.value = '';
              tableData.value = res.data;
              clearInterval(timer.value);
              timer.value = 0;
            } else {
              licenseSubmitSuccess.value = false;
              isContinue.value = false;
            }
          }
        } catch (e) {
          licenseSubmitSuccess.value = false;
          licenseProStatusOCR.value = 'exception';
          licenseContentOCR.value = '识别失败';
          clearInterval(timer.value);
          timer.value = 0;
        }
      }
    }

    const initCon = async () => {
      if (licensePath) {
        isInit.value = false;
        licenseUploadComplete.value = false;
        licensePercent.value = 100;
        licenseContent.value = '100%完成';
        licenseProStatus.value = "normal";
        isLicenseSuccess.value = true;
        const size = sessionStorage.getItem("vlLicenseFileSize");
        let num = 0;
        if (size) {
          num = parseFloat(size);
        }
        licenseFileSize.value = (num / 1024 / 1024).toFixed(2) + 'MB';
        sessionStorage.setItem("vlLicenseFileSize", '');
        await getLicenseResult();
        if (!licenseSubmitSuccess.value) {
          timer.value = setInterval(async () => {
            await getLicenseResult();
          }, 2000);
        }
      }
    }
    initCon();

    // 校验文件
    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isPdf) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照JPG PDF等格式进行文件上传";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        const fileLength = fileList.length;
        if (fileLength > 1) {
          params.noticeType = "error";
          params.content = "每次只能上传一张零售文件";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        return resolve(true);
      });
    };

    const submitLicense = async () => {
      try {
        const res = await licenseUpload(
          licenseConfig,
          data.vinNo,
          data.dealerCode,
          licenseFormData
        );
        if (res.code !== "0") {
          isLicenseSuccess.value = false;
          licenseProStatus.value = "exception";
          licenseContent.value = "上传失败";
          modalParams.content = res.message;
        } else {
          modalParams.content = "上传成功！";
          licenseProStatus.value = "normal";
          isLicenseSuccess.value = true;
        }
      } catch (e) {
        isLicenseSuccess.value = false;
        licenseProStatus.value = "exception";
        licenseContent.value = "上传失败";
        modalParams.content = "上传失败";
      }
    };

    // 上传文件
    const uploadFile = async () => {
      licenseProStatus.value = "normal";
      await submitLicense();
      if (isLicenseSuccess.value) {
        await getLicenseResult();
        if (!licenseSubmitSuccess.value) {
          timer.value = setInterval(async () => {
            await getLicenseResult();
          }, 2000);
        }
      } else {
        modalParams.noticeType = "error";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
        context.emit("get-modal-info", { modalVisible: true, modalData: modalData });
      }
    };

    const licenseCustomRequest = (source: any) => {
      licenseFormData.append("file", source.file);
      licenseFileName.value = source.file.name;
      licenseFileSize.value =
        (source.file.size / 1024 / 1024).toFixed(2) + "MB";
      licenseUploadComplete.value = false;
      isInit.value = false;
      // 自动上传
      uploadFile();
    };

    const handleCancelUpload = async () => {
      await cancelSingleUpload(data.vinNo, data.dealerCode);
      licenseUploadComplete.value = true;
      licenseSubmitSuccess.value = false;
      isContinue.value = false;
      if (timer.value) {
        clearInterval(timer.value);
        timer.value = 0;
    }
    }

    return {
      columns,
      modal,
      data,
      licensePath,
      close,
      initCon,
      beforeUpload,
      licenseUploadComplete,
      uploadFile,
      submitLicense,
      licenseCustomRequest,
      licensePercent,
      licenseConfig,
      licenseProStatus,
      licenseContent,
      isLicenseSuccess,
      isSuccess,
      modalData,
      licenseFileData,
      modalParams,
      licenseFileName,
      licenseFileSize,
      isContinue,
      timer,
      licensePercentOCR,
      licenseContentOCR,
      licenseProStatusOCR,
      licenseSubmitSuccess,
      tableData,
      isInit,
      closeSub,
      closNum,
      handleCancelUpload
    };
  },
});
