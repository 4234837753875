
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["close-batch-submit"],
  props: {
    batchSubmitVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(_, context) {
    const columns = [
      {
        title: "车架号",
        dataIndex: "vin",
      },
      {
        title: "提交结果",
        dataIndex: "result",
        width: 80,
      },
      {
        title: "失败原因",
        dataIndex: "reason",
      },
    ];

    const confirm = () => {
      context.emit("close-batch-submit");
    };
    return {
      columns,
      confirm,
    };
  },
});
