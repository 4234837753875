
import axios from "axios";
import { defineComponent, reactive, ref } from "vue";
import { FileItem } from "@/API/types";
import {
  batchLicenseFile,
  batchSubmitResult,
  batchFileSubmit,
  getBatchSubmitResultTotal,
} from "@/API/vl/vlOverview";
import { guid } from "@/utils/index";
import moment from "moment";
import downloadFile from "@/utils/rv/downloadFile";
import { useStore } from "vuex";
const columns = [
  {
    title: "车架号",
    dataIndex: "vinNo",
    key: "vinNo",
    width: 100,
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    key: "fileName",
    width: 150,
  },
  {
    title: "提交结果",
    dataIndex: "rtnMessage",
    key: "rtnMessage",
    width: 150,
    slots: { customRender: "rtnMessage" },
  },
];

export default defineComponent({
  emits: ["close-batch-visible", "get-modal-info"],
  props: {
    isBatchVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dealerCode = useStore().state.user.organization.entityCode;
    const isUploading = ref(false);
    const isCheckingOcr = ref(false);
    const isvisible = (props as any).isBatchVisible;
    //弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });

    //文件上传数量
    const licenseUploadComplete = ref<boolean>(true);
    const licenseCount = ref(0);
    const licenseFile = ref([]);
    const licenseContent = ref<string>("0/0");
    const licenseFileSize = ref<string>("");
    const licenseKey = ref<string>(guid());
    const licenseFormData = new FormData();
    //行驶证进度条
    const tempLicenseData = ref([]);
    const licenseTit = ref<string>("文件上传");
    const licensePercent = ref<number>(0);
    const licenseFileNum = ref<number>(0);
    const isLicenseSuccess = ref<boolean>(false);
    const licenseProStatus = ref<string>("normal");
    const uploadError = ref<boolean>(false);
    const licenseClickCount = ref<number>(0);
    // const licenseSubSuccess = ref<boolean>(false)

    //OCR进度条
    const isOcrSuccess = ref<boolean>(false);
    const orcTit = ref<string>("OCR识别");
    const ocrPercent = ref<number>(0);
    const ocrProStatus = ref<string>("normal");
    const ocrContent = ref<string>("");
    const ocrFileSize = ref<string>("");
    const ocrKey = ref<string>(guid());
    const ocrSuccess = ref<number>(0);

    const dataSource = ref({
      content: [],
    });
    const isContinue = ref<boolean>(false);
    const current = ref<number>(1);
    const size = ref<number>(10);
    const closeCount = ref<number>(0);
    const timer = ref<number>(0);
    let isCancelUpload = false;
    const close = () => {
      if (isCancelUpload) {
        setTimeout(() => {
          isCancelUpload = false;
        });
        return;
      }
      closeCount.value = 1;
      if (isUploading.value) {
        modalParams.noticeType = "warning";
        modalParams.content =
          "正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？";
        modalParams.btnVisible = true;
        Object.assign(modalData, modalParams);
        sessionStorage.setItem("batchNo", licenseKey.value);
        emit("get-modal-info", { modalVisible: true, modalData: modalData });
      } else {
        delete sessionStorage["batchNo"];
        emit("close-batch-visible", { isBatchVisible: false });
      }
    };
    // 重写默认上传方法 获取合规的行驶证图片信息
    const licenseCustomRequest = () => {
      if (licenseCount.value !== 1) {
        const size = ref<number>(0);
        licenseFile.value.forEach((ele: any) => {
          size.value = size.value + ele.size;
        });
        licenseFileNum.value = licenseFile.value.length;
        licenseContent.value = "0/" + licenseFileNum.value + "（0%完成）";
        licenseFileSize.value = (size.value / 1024 / 1024).toFixed(2) + "MB";
        licenseUploadComplete.value = false;
      }
    };
    let nowSelectedFileNum = 0;
    //预检查
    const beforeUpload = (file: FileItem, fileList: any) => {
      nowSelectedFileNum = fileList.length;
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        //判断文件格式
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isPdf) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照JPG PDF 等格式进行文件上传";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        //判断文件大小
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        //判断图片数量
        const fileLength = fileList.length;
        if (fileLength > 100) {
          params.noticeType = "error";
          params.content = "文件数量不能大于100张";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        licenseFile.value = fileList;
        tempLicenseData.value = fileList;
        return resolve(true);
      });
    };
    //文件上传进度条显示
    const CANCEL_TOKEN = axios.CancelToken;
    let cancelFunction = () => {
      console.log("no uploading");
    };
    const licenseConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          licensePercent.value = complete;
          licenseContent.value =
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                licenseFileNum.value
            ) +
            "/" +
            licenseFileNum.value +
            "（" +
            complete +
            "%完成）";
        }
      },
      cancelToken: new CANCEL_TOKEN((c) => {
        //强行中断请求要用到的，记录请求信息
        cancelFunction = () => {
          isCancelUpload = true;
          c();
        };
      }),
    };
    //批量上传行驶证
    const batchLicense = async () => {
      isUploading.value = true;
      try {
        const res: any = await batchLicenseFile(
          licenseConfig,
          licenseKey.value,
          dealerCode,
          licenseFormData
        );
        isUploading.value = false;
        //判断返回的code值 除0外都是异常
        if (res.code !== "0") {
          isLicenseSuccess.value = false;
          licenseProStatus.value = "exception";
          licenseContent.value = "上传失败";
          uploadError.value = true;
          modalParams.content = res.message;
        } else {
          licenseProStatus.value = "normal";
          isLicenseSuccess.value = true;
        }
      } catch (e) {
        if (isCancelUpload) {
          setTimeout(() => {
            isCancelUpload = false;
          });
          return;
        }
        isLicenseSuccess.value = false;
        uploadError.value = true;
        licenseProStatus.value = "exception";
        licenseContent.value = "上传失败";
        modalParams.content = "上传失败";
      }
    };

    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (licenseProStatus.value == "exception") {
          licenseUploadComplete.value = true;
          licenseProStatus.value = "normal";
          licensePercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete("files");
        }
        if (ocrProStatus.value == "exception") {
          licenseUploadComplete.value = true;
          ocrProStatus.value = "normal";
          ocrPercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete("files");
        }
      }
    };
    // 图片格式错误 再次上传清空之前记录数
    const isResetCount = (param: { continue: boolean }) => {
      const resetCount = param.continue;
      if (resetCount) {
        licenseCount.value = 0;
      }
    };
    // 获取提交结果
    const getBatchSubmitResult = async (batchNo: string) => {
      const params = {
        batchNo: batchNo,
        current: current.value,
        size: size.value,
      };
      await batchSubmitResult({ params }).then((res: any) => {
        dataSource.value = res;
      });
    };
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      getBatchSubmitResult(licenseKey.value);
    };
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      getBatchSubmitResult(licenseKey.value);
    };

    const resultNum = reactive({ total: 0, success: 0, fail: 13 });
    const getResultNum = (batchNo: string) => {
      getBatchSubmitResultTotal({
        batchNo,
      }).then((res: any) => {
        Object.assign(resultNum, res);
      });
    };
    // ocr提交获取进度
    const batchSub = async (type: string, batchNo: string) => {
      const params = {
        batchNo: batchNo,
      };
      isContinue.value = false;
      dataSource.value = {
        content: [],
      };
      try {
        const res = await batchFileSubmit({ params });
        if (res.code !== "0") {
          ocrProStatus.value = "exception";
          ocrContent.value = "提交失败";
          clearInterval(timer.value);
          timer.value = 0;
          modalParams.content = res.message;
        } else {
          const useCurrent = res.data.current;
          const useTotal = res.data.total;
          const percentage = ((useCurrent / useTotal) * 100) | 0;
          ocrProStatus.value = "normal";
          ocrPercent.value = percentage;
          ocrContent.value =
            useCurrent + "/" + useTotal + "（" + ocrPercent.value + "%完成）";
          if (useCurrent !== 0 && useCurrent == useTotal) {
            clearInterval(timer.value);
            timer.value = 0;
            const activeValue = ref("");
            if (licensePercent.value == 100) {
              activeValue.value = "ocr";
            }
            if (type !== activeValue.value) {
              isContinue.value = false;
              dataSource.value = {
                content: [],
              };
            } else {
              isCheckingOcr.value = false;
              await getBatchSubmitResult(batchNo);
              getResultNum(batchNo);
              isContinue.value = true;
            }
          } else {
            if (percentage == 100) {
              modalParams.content = "提交成功！";
            }
          }
        }
      } catch (e) {
        ocrProStatus.value = "exception";
        ocrContent.value = "提交失败";
        clearInterval(timer.value);
        timer.value = 0;
        modalParams.content = "提交失败";
      }
    };
    //ocr提交
    const batchSubmit = async () => {
      isCheckingOcr.value = true;
      ocrContent.value = "0/" + nowSelectedFileNum;
      timer.value = setInterval(async () => {
        await batchSub("ocr", licenseKey.value);
      }, 2000);
      sessionStorage.setItem("isOcrContinue", "ocr");
    };
    // 智传
    const submitFile = async (file: any) => {
      if (!(file && file.fileList.length == nowSelectedFileNum)) return;
      licenseProStatus.value = "normal";
      // 清空之前请求存储数据
      sessionStorage.setItem("vlLicenseData", "");
      // sessionStorage.setItem('ocrVlSuccess', '')
      sessionStorage.setItem("isOcrContinue", "");
      licenseClickCount.value = 1;
      // 转换上传数据格式
      (tempLicenseData as any).value.forEach((ele: any, index: number) => {
        licenseFormData.append("files", ele);
      });
      await batchLicense();
      // 判断上传是否成功 进行相关处理
      if (isCancelUpload) {
        setTimeout(() => {
          isCancelUpload = false;
        });
        return;
      }
      if (isLicenseSuccess.value === true) {
        ocrFileSize.value = licenseFileSize.value;
        licenseFileSize.value = "";
        // 传递相关需要保存的数据
        const licenseSession = reactive({
          vlLicenseData: tempLicenseData,
          isLicenseSuccess: true,
          licenseFileNum: licenseFileNum,
          licenseFileSize: licenseFileSize,
          licenseKey: licenseKey,
        });
        sessionStorage.setItem("vlLicenseData", JSON.stringify(licenseSession));

        // licenseSubSuccess.value = true
        // 上传成功调用提交接口
        modalParams.noticeType = "warning";
        modalParams.content =
          "OCR识别中，关闭窗口后可在任务中心关注后续进程及结果";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
        sessionStorage.setItem("batchNo", licenseKey.value);
        emit(
          "get-modal-info",
          { modalVisible: true, modalData: modalData },
          true,
          3
        );
        batchSubmit();
      } else {
        modalParams.noticeType = "error";
        modalParams.btnVisible = false;
        sessionStorage.setItem("vlLicenseData", "");
        emit("get-modal-info", { modalVisible: true, modalData: modalParams });
      }
    };
    //继续上传,清空数据
    const continueSumbit = () => {
      sessionStorage.setItem("vlLicenseData", "");
      sessionStorage.setItem("isOcrContinue", "");
      licenseUploadComplete.value = true;
      isContinue.value = false;
      licenseKey.value = guid();
      licensePercent.value = 0;
      ocrPercent.value = 0;
      licenseFormData.delete("files");
    };

    // 相关操作刷新方法
    const initCon = async () => {
      // 初始化先清空计时器
      clearInterval(timer.value);
      timer.value = 0;
      isContinue.value = false;
      dataSource.value = {
        content: [],
      };
      // 获取存储数据看是否请求过相关接口逻辑
      const licenseSessionData = sessionStorage.getItem("vlLicenseData");
      const isocrContinueValue = sessionStorage.getItem("isOcrContinue");

      if (licenseSessionData && licenseSessionData.length > 0) {
        //通过解析出来的内容里面的已上上传文件，显示在页面上
        const licenseSession = JSON.parse(licenseSessionData);
        licenseUploadComplete.value = false;
        licensePercent.value = 100;
        licenseFileSize.value = "";
        licenseKey.value = licenseSession.licenseKey;
        if (isocrContinueValue) {
          // 提交过调取存储的数据拿到key值调用提交进度接口
          timer.value = setInterval(async () => {
            await batchSub("ocr", licenseKey.value);
          }, 1000);
        }
      }
    };
    initCon();
    //导出提交结果
    const exportSubmitEvent = () => {
      const params = {
        url: `/rvapi/vl/dealer/submitResultExport?batchNo=` + licenseKey.value,
        method: "post",
        fileName: `${moment(new Date()).format("YYYYMMDDHHmmss")}.xlsx`,
      };
      downloadFile(params);
    };
    return {
      cancelFunction,
      continueSumbit,
      isCheckingOcr,
      isvisible,
      licenseUploadComplete,
      modalData,
      licenseTit,
      licenseContent,
      licenseFileSize,
      licenseConfig,
      licenseFileNum,
      isLicenseSuccess,
      licenseProStatus,
      uploadError,
      timer,
      licenseClickCount,
      isOcrSuccess,
      orcTit,
      ocrPercent,
      ocrProStatus,
      batchSubmit,
      dataSource,
      isContinue,
      batchSub,
      closeCount,
      licensePercent,
      isResetModal,
      close,
      beforeUpload,
      batchLicense,
      isResetCount,
      licenseCustomRequest,
      submitFile,
      ocrContent,
      ocrFileSize,
      getBatchSubmitResult,
      pageChange,
      sizeChange,
      columns,
      exportSubmitEvent,
      ocrKey,
      ocrSuccess,
      size,
      // licenseSubSuccess,
      resultNum,
    };
  },
  beforeUnmount() {
    this.cancelFunction();
    clearInterval(this.timer);
    this.timer = 0;
  },
});
